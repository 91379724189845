@media (max-width: 500px) {
    .MuiExpansionPanelDetails-root {
        padding: 0 16px 0 0 !important;
    }

    .MuiExpansionPanelSummary-root {
        padding: 0 16px !important;
        min-height: 48px !important;
        height: 48px !important;
    }
}

@media (min-width: 500px) {

}