@media (max-width: 500px) {
    .MuiTab-wrapper{
        font-size: 12px !important;
        font-weight: bold!important;
    }
    .MuiTab-root{
        padding: 6px 0!important;
    }
}

@media (min-width: 500px) {
}