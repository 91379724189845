@media (max-width: 500px) {
  .MuiTableCell-body {
    padding: 8px !important;
  }

  .MuiTableCell-head {
    padding: 2px 0 2px 8px !important;
    font-weight: bold !important;
    font-size: 0.85rem !important;
  }

  .MuiTableCell-body,
  .MuiTableCell-body > *,
  .MuiTableCell-body > * > *,
  .MuiTableCell-body > * > * > *:not(svg) {
    font-size: 0.85rem !important;
    font-weight: bold !important;
  }
}

@media (min-width: 500px) {
  .MuiTableCell-head,
  .MuiTableCell-body {
    padding: 12px !important;
    font-weight: bold !important;
  }
}
