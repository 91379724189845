* {
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  width: 750px;
  height: 500px;
  overflow: hidden;
}

@media screen and (max-width: 750px) {
  .video-container {
    width: 100%;
    height: 0;
  }
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MuiSwitch-sizeSmall + span {
  font-size: 0.9em;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.full-width {
  width: 100%;
}
.MuiAppBar-positionFixed {
  position: static !important;
}

.bold {
  font-family: sans-serif !important;
}

.banner {
  background-image: linear-gradient(270deg, #52ca95 0%, #1fc8db 51%, #2cb5e8 75%);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 4px 0 0;
  height: 64px;
  position: absolute;
  z-index: 100000;
  width: 100%;
}

.banner > .MuiButtonBase-root {
  border-radius: 50px !important;
  color: rgb(255, 255, 255) !important;
  border-color: #fff !important;
}

.no-margin {
  margin: 0 !important;
}
.small-margin {
  margin: 4 !important;
}

.normal-margin {
  margin: 8px !important;
}

.medium-margin {
  margin: 16px !important;
}
.normal-padding {
  padding: 8px !important;
}
.medium-padding {
  padding: 16px !important;
}
.search-dialog-fix {
  margin: 16px 8px 0 8px !important;
}
.icon {
  width: 50px;
  height: 50px;
  margin: 8px;
}
.fullScreen {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.flex {
  display: flex;
}

.flex-row,
.row {
  display: flex;
  flex-direction: row;
}
.wrap {
  flex-wrap: wrap;
}
.flex-column,
.col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  align-items: center;
}
.flex-start {
  align-items: flex-start;
}
.flex-end {
  align-items: flex-end;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.paper {
  background: #fff;
}
.card {
  width: fit-content;
  padding: 16px;
  border-radius: 8px;
  box-shadow: black 5px 5px 15px 5px;
}

.space-between {
  justify-content: space-between;
}
.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.fit-content {
  width: fit-content;
}

.MuiTypography-root {
  font-weight: bold !important;
}

.extra-large-font {
  font-size: 32px !important;
}
.large-font {
  font-size: 18px !important;
}
.medium-font {
  font-size: 16px !important;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.1;
}

.green {
  color: #4eb84e;
}

.disabled-red.Mui-disabled:disabled {
  color: #ff00008c;
}

.custom-snackbar.green > .MuiPaper-root {
  background-color: #4eb84e;
}

div.react-colorful {
  height: 150px;
}

.input-center input {
  text-align: center;
}
