/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
    cursor: pointer;

}

/* the text color of the drop label*/
.filepond--drop-label {
    color: #555;

}

/* underline color for "Browse" button */
.filepond--label-action {
    text-decoration-color: #aaa;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
    background-color: #eee;
}

/* the border radius of the drop area */
.filepond--panel-root {
    border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
    border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
    background-color: #555;
}

/* the background color of the drop circle */
.filepond--drip-blob {
    background-color: #999;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
    background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
    color: white;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
    box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */
.filepond--file {
    color: white;
}

/* error state color */
/*.error:nth-child(6).filepond--image-preview-overlay-failure {*/
/*    visibility: visible!important;*/
/*    opacity: 0.6!important;*/
/*}*/

/*.succeed:nth-child(6).filepond--image-preview-overlay-success.as{*/
/*    visibility: visible!important;*/
/*    opacity: 0.6!important;*/
/*}*/

