@media (max-width: 500px) {
  .MuiListItemSecondaryAction-root {
    right: 2px !important;
  }

  .MuiListItemAvatar-root {
    min-width: 52px;
  }

  .MuiListItemText-root:not(.fullWidth) {
    min-width: 100px !important;
    max-width: 180px !important;
    margin-right: 4px !important;
  }

  .MuiListItemSecondaryAction-root and .MuiIconButton-root {
    padding: 6px !important;
  }

  .MuiListItemText-root and .MuiTypography-body1 {
    font-size: 0.9rem !important;
  }

  .MuiListItemText-root and .MuiTypography-body2 {
    font-size: 0.7rem !important;
  }
}
.MuiListItemText-root > span,
.MuiListItemText-root > p {
  font-weight: bold !important;
}
